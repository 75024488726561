import React from 'react'
import { Link, navigate } from "gatsby"
import bullet from "../../images/arrow.png"
import PropTypes from 'prop-types';

function AuthMenu() {
 // console.log('data', data);
  return (
    <li className="nav-item">
    <Link
        className="nav-link"
        activeClassName="active"
        to="/app/register"  >
        Register
    </Link>
</li>
  )
}


AuthMenu.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AuthMenu
