import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import Logo from "../images/logo.png"
import starIcon from "../images/star.png"
import bullet from "../images/arrow.png"
import { isLoggedIn, logout } from "../services/auth"
import { getUser, authenticate, isAuth, signout } from "./helpers"
import { graphql } from "gatsby"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**  Component  ***/
import MobileMenuRituals from "./nav/menuRitualsMobile"
import SecondMobileMenu from "./nav/secondmenuMobile"
import ThirdMobileMenu from "./nav/thirdmenuMobile"
import FourthMobileMenu from "./nav/fourthmenuMobile"
import AuthMenu from "./nav/authmenu"

library.add(fab, fas)

// import PrivateRoute from "../components/privateRoute"
// import Profile from "../components/profile"
// import Login from "../components/login"

const MobileMenu = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light d-lg-none" id="mobileNav">
      <a className="navbar-brand" href="#">
        <img src={Logo} alt="Logo" className="logoImgTopMobile" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <MobileMenuRituals />
          <SecondMobileMenu />
          <ThirdMobileMenu />
          <FourthMobileMenu />
          {!isAuth() ? <AuthMenu /> : ""}
          <li className="nav-item">
            <Link
              className="nav-link"
              activeClassName="active"
              to="/app/scrapbook"
            >
              <i className="far fa-star d-lg-none d-xl-inline-block"></i>
              <span>
                <img src={starIcon} alt="" />{" "}
              </span>{" "}
              Scrapbook{" "}
            </Link>
          </li>

          {isAuth() ? (
            <li className="nav-item">
              {/* <small>
                Logged in as:&nbsp;
              </small><b>{getUser().firstName}</b> */}
              <Link
                href="/"
                className="nav-link"
                // id="userImage"
                activeClassName="active"
                to="/user-profile"
                onClick={event => {
                  event.preventDefault()
                  signout(() => navigate(`/app/login`))
                }}
              >
                {/* {" "}
                {getUser()
                  .firstName.substring(0, 2)
                  .toUpperCase()} */}
                Logout
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active"
                to="/app/login"
              >
                Login
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  )
}

export default MobileMenu
