import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import Logo from "../images/logo.png"
import starIcon from "../images/star.png"
import bullet from "../images/arrow.png"
import { isLoggedIn, logout } from "../services/auth"
import { getUser, authenticate, isAuth, signout } from "./helpers"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**  Component  ***/
import MenuRituals from "./nav/menuRituals"
import SecondMenu from "./nav/secondmenu"
import ThirdMenu from "./nav/thirdmenu"
import FourthMenu from "./nav/fourthmenu"
import AuthMenu from "./nav/authmenu"
import MobileMenu from "./mobileMenu"

library.add(fab, fas)

// import PrivateRoute from "../components/privateRoute"
// import Profile from "../components/profile"
// import Login from "../components/login"

const Header = ({ siteTitle }) => {
  return (
    <header id="navSection">
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light d-lg-flex d-none"
        id="homeNavbar"
      >
        <Link className="navbar-brand ml-xl-5 ml-lg-auto" to="/">
          <img src={Logo} alt="Logo" className="logoImgTop" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav lg-row mx-lg-auto" id="navContents">
            <MenuRituals />
            <SecondMenu />
            <ThirdMenu />
            <FourthMenu />

            {!isAuth() ? <AuthMenu /> : ""}

            <li className="nav-item">
            <a
                title = 'Find Lakhs of Indian Brides & Grooms in Kalyan Matrimony'
                className="nav-link"
                activeClassName="active"
                href="https://www.kalyanmatrimony.com/online-matrimony-website?utm_source=Kalyan_Shastra&utm_medium=website&utm_campaign=new"
                target="_blank"  >
                Kalyan Matrimony
            </a>
          </li>

          </ul>
          <ul className="navbar-nav">
            <li id="scrapBook" className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active"
                to="/app/scrapbook"
              >
                <i className="far fa-star d-lg-none d-xl-inline-block"></i>
                <span>
                  <img src={starIcon} alt="" />{" "}
                </span>{" "}
                Scrapbook{" "}
              </Link>
            </li>

            {isAuth() ? (
              <li id="userDetails" className="nav-item d-none d-lg-block">
                <a
                  href="/"
                  className="nav-link"
                  id="userImage"
                  activeClassName="active"
                  to="/user-profile"
                  onClick={event => {
                    event.preventDefault()
                    signout(() => navigate(`/app/login`))
                  }}
                >
                  {" "}
                  {getUser()
                    .firstName.substring(0, 2)
                    .toUpperCase()}
                </a>
              </li>
            ) : (
              <li id="userDetails" className="nav-item d-none d-lg-block">
                <Link
                  className="nav-link"
                  id="userImage"
                  activeClassName="active"
                  to="/app/login"
                >
                  <FontAwesomeIcon
                    icon={["fas", "sign-in-alt"]}
                    style={{ color: "#000000" }}
                  />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
      <MobileMenu />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
