import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import bullet from "../../images/arrow.png"
import PropTypes from "prop-types"

const MobileMenuRituals = ({ data }) => {
  const languages =
    data.allTaxonomyTermKalyanShastraCategories.edges[0].node.relationships
      .taxonomy_term__kalyan_shastra_categories
  console.log("languages", languages)

  return (
    <li className="nav-item active dropdown weddingRituals">
      <Link
        className="dropdown-toggle nav-link"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        to="/wedding-rituals"
        activeClassName="active"
      >
        {" "}
        Wedding Rituals &nbsp;
        <img className="arrowDrop" alt="" src={bullet} />
      </Link>{" "}
      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        <div className="row">
          {languages.map(language => (
            <div className="col-md-12 ">
              <div className="border">
                <p>{language.name}</p>
                <ul>
                  {language.relationships.taxonomy_term__kalyan_shastra_categories
                    .sort((a, b) => {
                      if (a.name < b.name) return -1
                      else if (a.name > b.name) return 1
                      else return 0
                    })
                    .map(casteData => (
                      <li key={casteData.id}>
                        <img className="arrow" alt="" src={bullet} />
                        <Link
                          to={casteData.path.alias}
                          activeClassName="active"
                        >
                          {casteData.name}{" "}
                        </Link>
                      </li>
                    ))}
                </ul>
                <div className="clearboth"></div>
                <Link className="float-right seeAll" to={language.path.alias}>
                  See All
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </li>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery1 {
        allTaxonomyTermKalyanShastraCategories(
          sort: { order: ASC, fields: relationships___parent }
          filter: { id: { eq: "f7342040-b19d-5fff-a235-4e49be5a6db6" } }
        ) {
          edges {
            node {
              id
              name
              path {
                alias
              }
              relationships {
                taxonomy_term__kalyan_shastra_categories {
                  id
                  name
                  path {
                    alias
                  }
                  relationships {
                    taxonomy_term__kalyan_shastra_categories {
                      name
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <MobileMenuRituals data={data} {...props} />}
  />
)

MobileMenuRituals.propTypes = {
  data: PropTypes.object.isRequired,
}
