import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import bullet from "../../images/arrow.png"
import PropTypes from "prop-types"

const Menu = ({ data }) => {
  const languages =
    data.allTaxonomyTermKalyanShastraCategories.edges[0].node.relationships
      .taxonomy_term__kalyan_shastra_categories
  console.log("languages", languages)

  return (
    <li className="nav-item active dropBtn">
      <Link
        className="nav-link first"
        activeClassName="active"
        to="/wedding-rituals"
      >
        {/* <a href="javascript:void(0)"></a> */}
        Wedding Rituals
      </Link>
      <div className="background traditional-rituals">
        <div className="container">
          <div className="dropdown-content">
            <div className="row pt-2 pb-4">
              {languages.map(language => (
                <div className="col-md-3 megamenu">
                  <p>{language.name}</p>
                  <div className="border pt-3 ">
                    <ul>
                      {language.relationships.taxonomy_term__kalyan_shastra_categories
                        .sort((a, b) => {
                          if (a.name < b.name) return -1
                          else if (a.name > b.name) return 1
                          else return 0
                        })
                        .map(casteData => (
                          <li key={casteData.id}>
                            <img className="arrow" alt="" src={bullet} />
                            <Link to={casteData.path.alias}>
                              {casteData.name}{" "}
                            </Link>
                          </li>
                        ))}
                    </ul>
                    <div className="clearboth"></div>
                    <Link className="float-right seeAll" to={language.path.alias}>
                      See All
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allTaxonomyTermKalyanShastraCategories(
          sort: { order: ASC, fields: relationships___parent }
          filter: { id: { eq: "f7342040-b19d-5fff-a235-4e49be5a6db6" } }
        ) {
          edges {
            node {
              id
              name
              path {
                alias
              }
              relationships {
                taxonomy_term__kalyan_shastra_categories {
                  id
                  name
                  path {
                    alias
                  }
                  relationships {
                    taxonomy_term__kalyan_shastra_categories {
                      name
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} {...props} />}
  />
)

Menu.propTypes = {
  data: PropTypes.object.isRequired,
}
