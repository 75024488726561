import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import bullet from "../../images/arrow.png"
import PropTypes from "prop-types"

const FourthMobileMenu = ({ data }) => {
  const ideas =
    data.allTaxonomyTermKalyanShastraCategories.edges[0].node.relationships
      .taxonomy_term__kalyan_shastra_categories

  return (
    <li className="nav-item dropdown">
      <Link
        className="dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        to="/ideas-and-tips"
        className="nav-link"
        activeClassName="active"
      >
        Ideas & Tips &nbsp;
        <img className="arrowDrop" alt="" src={bullet} />
      </Link>{" "}
      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        <div className="row">
          <div className="col-md-12">
            <div className="border">
              <ul>
                {ideas
                  .sort((a, b) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    else return 0
                  })
                  .map(Items => (
                    <li key={Items.id}>
                      <img className="arrow" alt="" src={bullet} />
                      <Link to={Items.path.alias}>{Items.name} </Link>
                    </li>
                  ))}
                {/* <li>
                  <img className="arrow" alt="" src={bullet} />
                  <Link to="/ideas-and-tips">See All</Link>
                </li> */}
              </ul>
              <div className="clearboth"></div>
              <Link className="float-right seeAll" to="/ideas-and-tips">
                See All
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query FourthMenuQuery2 {
        allTaxonomyTermKalyanShastraCategories(
          sort: { order: ASC, fields: relationships___parent }
          filter: { id: { eq: "3de534c7-6922-504d-a3ea-42f56449a127" } }
        ) {
          edges {
            node {
              id
              name
              path {
                alias
              }
              relationships {
                taxonomy_term__kalyan_shastra_categories {
                  id
                  name
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FourthMobileMenu data={data} {...props} />}
  />
)

FourthMobileMenu.propTypes = {
  data: PropTypes.object.isRequired,
}
