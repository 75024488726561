/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./header"
import "./layout.css"
import FooterLogo from '../images/f-logo.png';
import fb from '../images/fb.png'
import twitter from '../images/twitter.png'
import instagram from '../images/instagram.png'
import linkedin from '../images/linkedin.png'
import youtube from '../images/youtube.png'
import vimeo from '../images/vimeo.png'
import Helmet from 'react-helmet'
import { ToastContainer, toast } from 'react-toastify'
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <ToastContainer />
      <div>
        <main>{children}</main>
        <div className="clearFix"></div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-12">
                <Link to="/" className="p-0 m-0">
                  {" "}
                  <img src={FooterLogo} alt="" className="logoImgFoot" />
                </Link>
              </div>
              <div className="col-md-6 col-12 d-none d-md-block">
                <div className="copyRight">Copyright © Kalyanshastra</div>
              </div>
              <div className="col-md-3 col-12">
                <ul className="social_links social_links_footer mx-auto mx-lg-0">
                  <li>
                    <img src={fb} alt="" />
                  </li>
                  <li>
                    <img src={twitter} alt="" />
                  </li>
                  <li>
                    <img src={instagram} alt="" />
                  </li>
                  <li>
                    <img src={linkedin} alt="" />
                  </li>
                  <li>
                    <img src={youtube} alt="" />
                  </li>
                  <li>
                    <img src={vimeo} alt="" />
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-12 d-block d-md-none">
                <div className="copyRight">Copyright © Kalyanshastra</div>
              </div>
            </div>
          </div>
          <Helmet>
            <script src="//assets.pinterest.com/js/pinit.js"></script>
          </Helmet>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
