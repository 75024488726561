import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import bullet from "../../images/arrow.png"
import PropTypes from "prop-types"

const SecondMobileMenu = ({ data }) => {
  // console.log("data == " + data);
  // console.log(data)
  const planning =
    data.allTaxonomyTermKalyanShastraCategories.edges[0].node.relationships
      .taxonomy_term__kalyan_shastra_categories
  console.log(planning)
  //  console.log('data', data);
  return (
    <li className="nav-item  dropdown">
      <Link
        className="dropdown-toggle nav-link"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        activeClassName="active"
      >
        {" "}
        Wedding Planning &nbsp;
        <img className="arrowDrop" alt="" src={bullet} />
      </Link>{" "}
      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        <div className="row">
          <div className="col-md-12">
            <div className="border">
              <ul>
                {planning
                  .sort((a, b) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    else return 0
                  })
                  .map(Items => (
                    <li key={Items.id}>
                      <img className="arrow" alt="" src={bullet} />
                      <Link to={Items.path.alias}>{Items.name} </Link>
                    </li>
                  ))}
              </ul>
              <div className="clearboth"></div>
              <Link className="float-right seeAll" to="/weddding-planning">
                See All
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query SecondMenuQuery2 {
        allTaxonomyTermKalyanShastraCategories(
          sort: { order: ASC, fields: relationships___parent }
          filter: { id: { eq: "151a5179-a6ef-5015-bdd9-4c14d5ed45ee" } }
        ) {
          edges {
            node {
              id
              name
              path {
                alias
              }
              relationships {
                taxonomy_term__kalyan_shastra_categories {
                  id
                  name
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <SecondMobileMenu data={data} {...props} />}
  />
)

SecondMobileMenu.propTypes = {
  data: PropTypes.object.isRequired,
}
