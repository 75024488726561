import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import bullet from "../../images/arrow.png"
import PropTypes from "prop-types"

const FourthMenu = ({ data }) => {
  const ideas =
    data.allTaxonomyTermKalyanShastraCategories.edges[0].node.relationships
      .taxonomy_term__kalyan_shastra_categories

  return (
    <li className="nav-item active dropBtn ideas">
      <Link className="nav-link" activeClassName="active" to="/ideas-and-tips/">
        Ideas &amp; Tips{" "}
      </Link>
      <div className="background">
        <div className="container">
          <div className="dropdown-content">
            <div className="row pt-4 pb-4">
              <div className="col-md-12 megamenu">
                <div className="border pt-3 ">
                  <ul>
                    {ideas
                      .sort((a, b) => {
                        if (a.name < b.name) return -1
                        else if (a.name > b.name) return 1
                        else return 0
                      })
                      .map(Items => (
                        <li key={Items.id}>
                          <img className="arrow" alt="" src={bullet} />
                          <Link to={Items.path.alias}>{Items.name} </Link>
                        </li>
                      ))}
                  </ul>
                  <div className="clearboth"></div>
                  {/* <Link className="float-right seeAll" href="#">
                                              See All
                            </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query FourthMenuQuery {
        allTaxonomyTermKalyanShastraCategories(
          sort: { order: ASC, fields: relationships___parent }
          filter: { id: { eq: "3de534c7-6922-504d-a3ea-42f56449a127" } }
        ) {
          edges {
            node {
              id
              name
              path {
                alias
              }
              relationships {
                taxonomy_term__kalyan_shastra_categories {
                  id
                  name
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FourthMenu data={data} {...props} />}
  />
)

FourthMenu.propTypes = {
  data: PropTypes.object.isRequired,
}
